var temp = {
}

var globalData = {

}

module.exports = {
    temp: temp,
    globalData: globalData
};