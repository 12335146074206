<template>
  <div>
    <div class="form">
      <van-cell-group >
        <van-field
          size="large"
          type="number"
          v-model="insurance.declareAmount"
          :label="$t('保价金额')"
          @input="onPriceChange"
          :placeholder="$t('请输入人民币金额')"
        >
        </van-field>
        <van-field
          size="large"
          v-model="insurance.insuranceFee"
          :label="$t('保费')"
          :placeholder="
            $t('按保价金额的%自动计算', { rate: addedValueConfig.insurance })
          "
          readonly
        />
      </van-cell-group>

      <div class="saveButton">
        <van-button
          round
          block
          @click="doConfirm"
          type="primary"
          :loading="submitLoading"
          :disabled="submitLoading || !insurance.insuranceFee || !agreeTerms"
        >
          {{ $t("确定") }}
        </van-button>

        <van-button style="margin-top: 10px" round block @click="doCancel">
          {{ $t("取消保险") }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Cell, CellGroup, Toast, Button } from "vant";

import { temp } from "@/utils/temp";

export default {
  components: {
    [Field.name]: Field,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Button.name]: Button,
  },
  data() {
    return {
      insurance: {},
      addedValueConfig: {},
      agreeTerms: true,
      submitLoading: false,
    };
  },
  methods: {
    onPriceChange(value) {
      const maxAmount = this.$t("最高保价金额人民币", {
        amount: this.addedValueConfig.insuranceLimitAmount,
      });
      if (value) {
        this.insurance.declareAmount = value;
        if (value > 0) {
          if (value > this.addedValueConfig.insuranceLimitAmount) {
            Toast.fail(maxAmount);
            return;
          }
          this.insurance.insuranceFee = Number.parseFloat(
            (this.addedValueConfig.insurance *
              parseFloat(this.insurance.declareAmount)) /
              100
          ).toFixed(0);
        }
      } else {
        this.insurance.insuranceFee = null;
      }
    },
    doConfirm() {
      const maxAmount = this.$t("最高保价金额人民币", {
        amount: this.addedValueConfig.insuranceLimitAmount,
      });
      if (
        this.insurance.declareAmount >
        this.addedValueConfig.insuranceLimitAmount
      ) {
        Toast.fail(maxAmount);
        return;
      }

      temp.insurance = this.insurance;
      this.$router.go(-1);
    },
    doCancel() {
      temp.insurance = {};
      this.$router.go(-1);
    },
  },
  mounted() {
    const {
      insurance,
      insuranceLimitAmount,
      declareAmount,
      insuranceFee,
    } = this.$route.query;

    this.addedValueConfig = {
      insurance: parseFloat(insurance),
      insuranceLimitAmount: parseFloat(insuranceLimitAmount),
    };

    this.insurance = {
      declareAmount: parseFloat(declareAmount) || null,
      insuranceFee: parseFloat(insuranceFee) || null,
    };
  },
};
</script>
<style lang="less" scoped>
.form {
}
.saveButton {
  margin: 15px 15px 0 15px;
}
.terms {
  padding: 10px 16px;
}
.terms-label {
  font-size: 14px;
}

.desc {
  background-color: #fff;
  margin-top: 30px;
  padding: 10px 20px;
}
.desc-row {
  padding: 3px;
}
</style>